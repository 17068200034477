import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import { scroller } from 'react-scroll'
import DatePicker from 'react-datepicker';
import axios from "axios";
import * as constClass from './Constants';
import AssetModal from './AssetModal';
import KeywordModal from './KeywordModal';
import FilterModal from './FilterModal';
import Slider from './Slider';

import '../datepicker.css'
import { stringify } from 'postcss';

function zeroDate(date) {
    date.setHours(0,0,0)
    return date
}

class Investigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            item: {
                id: props.id,
                name: "",
                description: "",
                active: false,
                created: Date(),
                and_strategy: true,
                user: {
                    first_name: "", 
                    last_name: "", 
                    email: "", 
                    client: {
                        company_name: ""
                    }
                },
                assets: [],
                filters: [],
                classifications: [],
                visualizations: []
            },
            formItem: {},
            showAssetModal: false,
            showKeywordModal: false,
            showFilterModal: false,
            openAssest: [],
            rangeStartDate: new Date('2022-10-01T00:00:00'),
            rangeEndDate: new Date('2022-12-01T00:00:00'),
            andStrategy: true,
            isExported: false,
            // filteredPlatforms: [1,2],
            columnHeight: 0,
            bottomOffsetTop: props.bottomOffsetTop
        }
        this.defaultClassifications = [
            {name:'hate', field:'hatescore_float', start_value:0, end_value:100},
            // {name:'toxic', field:'hatescore2', start_value:0, end_value:100},
            {name:'toxic', field:'perspective.toxicity_score', start_value:0, end_value:100},
            {name:'threat', field:'perspective.threat_score', start_value:0, end_value:100},
            {name:'identity', field:'perspective.identity_attack_score', start_value:0, end_value:100}
        ];
    }

    componentDidMount() {
        this.getItem(this.state.item);

        // window.addEventListener('resize', this.updateColumneight);
    }

    setColumneight = () => {
        var columnsStart = this.columnsElement.getBoundingClientRect();
        var columnHeight = this.state.bottomOffsetTop - columnsStart.top - 10;
        if (columnHeight < 150) {
            columnHeight = 150;
        }
        this.setState({ columnHeight });
    };

    setRangeStart = (date) => {
	    date.setHours(0,0,0)
        this.setState({ rangeStartDate: date });
    };

    setRangeEnd = (date) => {
	    date.setHours(0,0,0)
        this.setState({ rangeEndDate: date });
    };

    // filterPlatforms = (selectedPlatforms) => {
    //     this.setState({ filteredPlatforms: selectedPlatforms });
    // };

    getItem = (item) => {
        axios
            .get(`${constClass.BACKEND_URI}/backend/api/investigations/${item.id}/`, getHeaders())
            .then((res) => {
                const openAssets = this.state.openAssest;
                const temp = res.data;
                console.log(temp);
                if (typeof temp.and_strategy === 'undefined') {
                    temp.and_strategy = this.state.andStrategy;
                }
                
                // let firstStartDate = new Date();
                temp.assets.forEach(function (asset, index) {
                    if (openAssets.includes(asset.id)) {
                        temp.assets[index].open = true;
                    }
                });

                var classifications = [];
                this.defaultClassifications.forEach(function (item, index) {
                    const exists = temp.classifications.find((element) => element.field === item.field);
                    if (exists) {
                        classifications[index] = exists;
                    } else {
                        classifications[index] = item;
                    }
                });
                temp.classifications = classifications;
                // console.log(temp);

                this.setState({ item: temp });
                this.setState({ isLoading: false });
                this.setColumneight();
            })
            .catch((err) => console.log(err));
    };

    exportItem = () => {
        console.log(this.state);
        let openAssets = [];
        this.state.item.assets.forEach(function (asset) {
            if (asset.active && asset.keywords) {
                let openProfileIds = [];
                asset.keywords.forEach(function (keyword) {
                    if (keyword.active) {
                        keyword.platforms.forEach(function (platform) {
                            openProfileIds.push({
                                keywordId:keyword.id, 
                                profileId: platform.profileID
                            });
                        });
                    }
                });
                if (openProfileIds.length) {
                    openAssets.push({
                        id:asset.id, 
                        keywords:openProfileIds
                    });
                }
            }
        });
        let openClassifications = [];
        this.state.item.classifications.forEach(function (classification) {
            if (classification.active) {
                openClassifications.push({
                    id:classification.id, 
                    field:classification.field, 
                    start:classification.start_value, 
                    end:classification.end_value
                });
            }
        });
        let openFilters = [];
        this.state.item.filters.forEach(function (filter) {
            if (filter.active) {
                openFilters.push({
                    id:filter.id
                });
            }
        });

        const stateExport = {
            activeAssets: openAssets,
            filterPeriods: {
                start:this.state.rangeStartDate, 
                end:this.state.rangeEndDate
            },
            classificationStrategy: (this.state.item.and_strategy ? 'and' : 'or'),
            activeClassifications: openClassifications,
            activeFilters: openFilters
        };
        const exportSettins = {'exportsettings': JSON.stringify(stateExport)};
        console.log(exportSettins);

        axios
            .post(`${constClass.BACKEND_URI}/backend/api/download/`, exportSettins, getHeaders())
            .then((res) => {
                console.log(res);
                this.setState({ isExported: !this.state.isExported });
            })
            .catch((err) => console.log(err));
    };

    scrollToDashboard() {
        scroller.scrollTo('dashboard', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    createAsset = () => {
        const asset = { name: "", description: "", active: true };
        this.setState({ formItem: asset, showAssetModal: !this.state.showAssetModal });
    };

    editAsset = (item) => {
        this.setState({ formItem: {id: item.id, name: item.name, description: item.description}, showAssetModal: !this.state.showAssetModal });
    };
    
    toggleAssetModal = () => {
        this.setState({ showAssetModal: !this.state.showAssetModal });
    };
    
    handleAssetSubmit = (asset) => {
        this.toggleAssetModal();

        asset.investigation = [this.state.item.id];

        if (asset.id) {
            axios
                .put(`${constClass.BACKEND_URI}/backend/api/assets/${asset.id}/`, asset, getHeaders())
                .then((res) => this.getItem(this.state.item));
            return;
        }

        axios
            .post(`${constClass.BACKEND_URI}/backend/api/assets/`, asset, getHeaders())
            .then((res) => {
                this.getItem(this.state.item);
                this.setState({ isExported: !this.state.isExported });
            })
            .catch((err) => console.log(err));
    };
      
    handleAssetDelete = (asset) => {
        // disconnect from investigation
        var investigationId = this.state.item.id,
            assetInvestigations = asset.investigation;

        for( var i = 0; i < assetInvestigations.length; i++){ 
            if ( assetInvestigations[i] === investigationId) { 
                assetInvestigations.splice(i, 1); 
            }
        }

        asset.investigation = assetInvestigations;
        delete asset.keywords;

        axios
            .put(`${constClass.BACKEND_URI}/backend/api/assets/${asset.id}/`, asset, getHeaders())
            .then((res) => this.getItem(this.state.item));
    };
    
    setAssetActive = (e, index, asset) => {
        e.preventDefault();
        const temp = this.state.item;
        asset.active = !asset.active;

        let save = {
            id: asset.id, 
            name: asset.name, 
            description: asset.description, 
            active: asset.active,
            investigation: asset.investigation
        }
        
        axios
            .put(`${constClass.BACKEND_URI}/backend/api/assets/${save.id}/`, save, getHeaders());

        temp.assets[index] = asset;
        this.setState({ item: temp });
        this.setState({ isExported: !this.state.isExported });
        this.scrollToDashboard();
    };

    // Classifications
    onStrategyChange = (e) => {
        const temp = this.state.item;
        temp.and_strategy = e.target.value === 'and';
        this.setState({ item: temp });
        this.setState({ isExported: !this.state.isExported });
        axios
            .put(`${constClass.BACKEND_URI}/backend/api/investigations/${temp.id}/`, {"and_strategy": temp.and_strategy}, getHeaders())
            .then((res) => {
                this.getItem(this.state.item);
                this.setState({ isExported: !this.state.isExported });
            })
            .catch((err) => console.log(err));
    }

    handleClassificationSubmit = (index, classification) => {
        classification.investigation = this.state.item.id;
        classification.active = true;
        if (this.state.item.classifications[index].id) {
            classification.id = this.state.item.classifications[index].id;
        }

        if (classification.id) {
            axios
                .put(`${constClass.BACKEND_URI}/backend/api/classifications/${classification.id}/`, classification, getHeaders())
                .then((res) => {
                    this.getItem(this.state.item);
                    this.setState({ isExported: !this.state.isExported });
                });
            return;
        }

        axios
            .post(`${constClass.BACKEND_URI}/backend/api/classifications/`, classification, getHeaders())
            .then((res) => {
                this.getItem(this.state.item);
                this.setState({ isExported: !this.state.isExported });
            })
            .catch((err) => console.log(err));
    }

    setClassificationActive = (e, index, classification) => {
        e.preventDefault();
        const temp = this.state.item;
        classification.active = !classification.active;

        let save = {
            id: classification.id, 
            name: classification.name, 
            field: classification.field, 
            start_value: classification.start_value, 
            end_value: classification.end_value, 
            active: classification.active,
            investigation: classification.investigation
        }
        
        axios
            .put(`${constClass.BACKEND_URI}/backend/api/classifications/${save.id}/`, save, getHeaders());

        temp.classifications[index] = classification;
        this.setState({ item: temp });
        this.setState({ isExported: !this.state.isExported });
        this.scrollToDashboard();
    };

    handleClassificationDelete = (id) => {
        axios
            .delete(`${constClass.BACKEND_URI}/backend/api/classifications/${id}/`, getHeaders())
            .then((res) => this.getItem(this.state.item));
    };
    
    createKeyword = (assetId) => {
        const keyword = {
            name: "", 
            // language: "",
            languages: [],
            description: "", 
            start_date: "", 
            end_date: "", 
            platforms: [],
            asset: assetId, 
            active: true,
            allow_past: true 
        };
        this.setState({ formItem: keyword, showKeywordModal: !this.state.showKeywordModal });
    };
    
    editKeyword = (item) => {
        const startDate = item.start_date ? new Date(item.start_date) : null;
        const endDate = item.end_date ? new Date(item.end_date) : null;

        const keyword = {
            id: item.id, 
            name: item.name,
            // language: item.language, 
            languages: [], 
            description: item.description, 
            start_date: startDate, 
            end_date: endDate, 
            platforms: item.platforms, 
            asset: item.asset,
            allow_past: (startDate > new Date())
        };
        this.setState({ formItem: keyword, showKeywordModal: !this.state.showAssetModal });
    };
    
    toggleKeywordModal = () => {
        this.setState({ showKeywordModal: !this.state.showKeywordModal });
    };
    
    handleKeywordSubmit = (keyword) => {
        this.toggleKeywordModal();

        keyword.start_date = this.formatDate(keyword.start_date);
        if (keyword.end_date !== '') {
            keyword.end_date = this.formatDate(keyword.end_date);
        } else {
            delete keyword.end_date;
        }

        if (keyword.id) {
            axios
                .put(`${constClass.BACKEND_URI}/backend/api/keywords/${keyword.id}/`, keyword, getHeaders())
                .then((res) => {
                    this.getItem(this.state.item);
                });
            return;
        }

        keyword.investigation = this.state.item.id;

        if (keyword.languages.length) {
            let twitterKey;
            for (let i = 0; i < keyword.platforms.length; i++) {
                if (keyword.platforms[i].platform_type === 1) {
                    twitterKey = i;
                    break;
                }
            }

            if (typeof(twitterKey) !== 'undefined') {
                for (let language of keyword.languages) {
                    const platform = {
                        platform_type: 1,
                        language: language.value
                    }
                    keyword.platforms.push(platform);
                }

                keyword.platforms.splice(twitterKey, 1);
            }

            delete keyword.languages;
        }

        axios
            .post(`${constClass.BACKEND_URI}/backend/api/keywords/`, keyword, getHeaders())
            .then((res) => 
                this.getItem(this.state.item)
            )
            .catch((err) => console.log(err));
    };

    handleKeywordDelete = (item) => {
        axios
            .delete(`${constClass.BACKEND_URI}/backend/api/keywords/${item.id}/`, getHeaders())
            .then((res) => {
                this.getItem(this.state.item)
            });
    };

    setKeywordActive = (e, assetIndex, keywordIndex, keyword) => {
        e.preventDefault();
        const temp = this.state.item;
        keyword.active = !keyword.active;

        for (let platform of keyword.platforms) {
            if (platform.language === '') {
                delete platform.language;
            }
        }


        let save = {
            id: keyword.id, 
            name: keyword.name, 
            description: keyword.description, 
            active: keyword.active,
            asset: keyword.asset,
	        platforms: keyword.platforms,
        }

        axios
            .put(`${constClass.BACKEND_URI}/backend/api/keywords/${save.id}/`, save, getHeaders());

        temp.assets[assetIndex].keywords[keywordIndex] = keyword;
        this.setState({ item: temp });
        this.setState({ isExported: !this.state.isExported });
        this.scrollToDashboard();
    };

    createFilter = (investigationId) => {
        const filter = {
            name: "", 
            investigation: investigationId
        };
        this.setState({ formItem: filter, showFilterModal: !this.state.showFilterModal });
    };
    
    toggleFilterModal = () => {
        this.setState({ showFilterModal: !this.state.showFilterModal });
    };
    
    handleFilterSubmit = (filter) => {
        this.toggleFilterModal();

        if (filter.id) {
            axios
                .put(`${constClass.BACKEND_URI}/backend/api/filters/${filter.id}/`, filter, getHeaders())
                .then((res) => this.getItem(this.state.item));
            return;
        }

        axios
            .post(`${constClass.BACKEND_URI}/backend/api/filters/`, filter, getHeaders())
            .then((res) => 
                this.getItem(this.state.item)
            )
            .catch((err) => console.log(err));
    };

    handleFilterDelete = (item) => {
        axios
            .delete(`${constClass.BACKEND_URI}/backend/api/filters/${item.id}/`, getHeaders())
            .then((res) => {
                this.getItem(this.state.item)
            });
    };

    setFilterActive = (e, filterIndex, filter) => {
        e.preventDefault();
        const temp = this.state.item;
        filter.active = !filter.active;

        temp.filters[filterIndex] = filter;
        this.setState({ item: temp });
        this.setState({ isExported: !this.state.isExported });
        this.scrollToDashboard();
    };

    setOpen = (asset, index) => {
        const temp = this.state.item;
        asset.open = !asset.open;

        temp.assets[index] = asset;
        this.setState({ item: temp });

        const openAssets = this.state.openAssest;
        if (asset.open) {
            openAssets.push(asset.id);
        } else {
            for( var i = 0; i < openAssets.length; i++){ 
                if ( openAssets[i] === asset.id) { 
                    openAssets.splice(i, 1); 
                }
            }
        }

        this.setState({ openAssets: openAssets });
    };

    toDate = (date) => {
        if (date === null) {
            return null;
        }
        return new Date(date);
    };

    formatDate = (date, format) => {
        if (date === null) {
            return null;
        }
        if (date instanceof Date && !isNaN(date.valueOf())) {
        } else {
            date = new Date(date);
        }
        const year = date.getFullYear();
        const month = date.getMonth() +1;
        const day = date.getDate();
        if (format === 'dd/MM/Y') {
            return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;
        }
        return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    };

    render() {
        const item = this.state.item
        const assets = item.assets
        const filters = item.filters
        const classifications = item.classifications
        const visualizations = item.visualizations
        const visualization = visualizations.length ? visualizations[0]: null;
        const keywordQuery = [];
        const filterQuery = [];
        const platformQuery = [];
        const classQuery = [];

        item.assets.forEach(function (asset) {
            if (asset.active && asset.keywords) {
                asset.keywords.forEach(function (keyword) {
                    if (keyword.active) {
                        keyword.platforms.forEach(function (platform) {
                            keywordQuery.push("profileID:"+ platform.profileID +"");
                        });
                    }
                });
            }
        });

        filters.forEach(function (filter) {
            if (filter.active) {
                filterQuery.push("(query:(match_phrase:(originalContent:'*!'"+ filter.name +"!'*')))");
            }
        });

        if (keywordQuery.length === 0) {
            keywordQuery.push("profileID:000000");
        }

        classifications.forEach(function (classification) {
            if (classification.active) {
                classQuery.push('(' + classification.field + '%20>=%20' + (classification.start_value /100) + '%20and%20' + classification.field + '%20<=%20' + (classification.end_value /100) + ')');
            }
        });
        // console.log(classQuery);
        // console.log(this.state.item.and_strategy);

        return (
          <>
            {this.state.isLoading ? '...' : 
                <div className="bg-white px-4 pt-3 border-b border-gray-200 sm:px-6 sm:rounded-lg">
                    <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
                        <div className="mt-4">
                            <h2 className="text-2xl leading-6 font-medium text-gray-900">{item.name}</h2>
                            <p className="mt-1 text-sm text-gray-500">
                                {item.description}
                            </p>
                        </div>
                        <div className="mt-4 flex-shrink-0">
                            <button
                                className={'mr-5 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-' + (this.state.isExported ? 'green' : 'indigo') + '-600 hover:bg-' + (this.state.isExported ? 'green' : 'indigo') + '-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'}
                                onClick={this.exportItem}
                            >
                                Export
                            </button>
                            <span className="mt-1 text-sm text-gray-500">
                                Added on {this.formatDate(item.created, 'dd/MM/Y')} by {item.user.first_name}
                            </span>
                        </div>
                    </div>
                    <div className="pt-5 overflow-hidden">
                        <div 
                            className="h-full flex"
                            ref={ (columnsElement) => { this.columnsElement = columnsElement } }
                        >
                            <div 
                                className="flex flex-shrink-0"
                                style={{maxHeight: this.state.columnHeight + 'px'}}
                            >
                                <div className="flex flex-col w-64">
                                    <div className="flex-1 flex flex-col overflow-y-auto">
                                        <div className="m-2 relative">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-300" />
                                            </div>
                                            <div className="relative flex justify-center">
                                                <span className="px-2 bg-white text-sm text-gray-500">Collect your data</span>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            {assets.length <= 0 ? 
                                                null : 
                                                <>
                                                    <ul className="border-b divide-y divide-gray-200">
                                                        {assets.map((asset, index) => {
                                                            return (
                                                                <li 
                                                                    key={asset.id}
                                                                    className={'border relative bg-white'}
                                                                >
                                                                    <div className='group bg-gray-100 p-2 pr-3'>
                                                                        <a 
                                                                            href="#dashboard" 
                                                                            onClick={e => this.setAssetActive(e, index, asset)}
                                                                            className={(asset.active ? 'text-green-600 ' : 'text-gray-900') + 'text-l leading-6 font-medium py-3'}
                                                                        >
                                                                            <i className={'fas ' + (asset.active ? 'fa-toggle-on' : 'fa-toggle-off') + ' mr-1'}></i> {asset.name}
                                                                        </a>
                                                                        <p className={'mt-1 text-sm text-gray-500' + (asset.open ? '' : ' truncate')} title={asset.description}>{asset.description}</p>
                                                                        <div className="invisible group-hover:visible absolute -top-2 right-5">
                                                                            <button
                                                                                className="mr-2 items-center border border-transparent rounded-md text-sm font-medium text-grey-500 hover:text-grey-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grey-500"
                                                                                onClick={() => this.editAsset(asset)}
                                                                                title="Edit"
                                                                            >
                                                                                <i className="fas fa-edit"></i>
                                                                            </button>
                                                                            <button
                                                                                className="mr-2 items-center border border-transparent rounded-md text-sm font-medium text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                                onClick={() => {
                                                                                    const confirmBox = window.confirm(
                                                                                        "Do you really want to disconnect this asset from the investigation?"
                                                                                    )
                                                                                    if (confirmBox === true) {
                                                                                        this.handleAssetDelete(asset)
                                                                                    }
                                                                                }}
                                                                                title="Delete"
                                                                            >
                                                                                <i className="fas fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <button 
                                                                            onClick={(event) => {
                                                                                event.preventDefault()
                                                                                this.setOpen(asset, index)
                                                                            }}
                                                                            className={'absolute top-4 right-2'}
                                                                        >
                                                                            <i className={'fas ' + (asset.open ? 'fa-chevron-up' : 'fa-chevron-right')}></i>
                                                                        </button>
                                                                    </div>
                                                                    <Collapse isOpened={asset.open}>
                                                                        {asset.keywords.length <= 0 ? 
                                                                            <p className="p-2 text-sm text-gray-400">No keywords yet</p> : 
                                                                            <ul className="divide-y divide-gray-200">
                                                                                {asset.keywords.map((keyword, k_index) => {
                                                                                    const activeKeyword = asset.active && keyword.active;
                                                                                    return (
                                                                                        <li 
                                                                                            key={keyword.id} 
                                                                                            className="relative block hover:bg-gray-50"
                                                                                        >
                                                                                            <div className="group flex-1 p-2 pr-3 text-sm truncate">
                                                                                                {asset.active ? 
                                                                                                    <a 
                                                                                                        href="#dashboard" 
                                                                                                        onClick={e => this.setKeywordActive(e, index, k_index, keyword)}
                                                                                                        className={'font-medium'}
                                                                                                        title={keyword.name}
                                                                                                    >
                                                                                                        <i className={'mr-1 fas ' + (activeKeyword ? 'text-green-600 fa-toggle-on' : 'text-gray-900 fa-toggle-off')}></i> {keyword.name}
                                                                                                    </a>
                                                                                                    : 
                                                                                                    <span title={keyword.name}>{keyword.name}</span>
                                                                                                }
                                                                                                <p className="text-gray-300">
                                                                                                    <span className="mr-1">
                                                                                                    {
                                                                                                        keyword.platforms.map(p=>p.platform_type).includes(1) ? 
                                                                                                            (
                                                                                                                <i className="fab fa-twitter text-blue-500 mr-1" title="scrapes twitter"></i>
                                                                                                            ) : 
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        keyword.platforms.map(p=>p.platform_type).includes(2) ? 
                                                                                                            (
                                                                                                                <i className="fab fa-instagram text-blue-500 mr-1" title="scrapes instagram"></i>
                                                                                                            ) : 
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        keyword.platforms.map(p=>p.platform_type).includes(3) ? 
                                                                                                            (
                                                                                                                <i className="fab fa-tiktok text-blue-500 mr-1" title="scrapes tiktok"></i>
                                                                                                            ) : 
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        keyword.platforms.map(p=>p.platform_type).includes(4) ? 
                                                                                                            (
                                                                                                                <i className="fab fa-reddit text-blue-500 mr-1" title="scrapes reddit"></i>
                                                                                                            ) : 
                                                                                                            null
                                                                                                    }
                                                                                                    </span>
                                                                                                    {
                                                                                                        keyword.start_date && keyword.end_date ? 
                                                                                                            <span>{this.formatDate(keyword.start_date, 'dd/MM/Y')} - {this.formatDate(keyword.end_date, 'dd/MM/Y')}</span> : 
                                                                                                            (
                                                                                                                keyword.start_date ? 
                                                                                                                    <span>{(this.toDate(keyword.start_date) > new Date() ? 'starting' : 'since')} {this.formatDate(keyword.start_date, 'dd/MM/Y')}</span> : 
                                                                                                                    <span>-</span>
                                                                                                            )
                                                                                                    }
                                                                                                </p>
                                                                                                <div className="invisible group-hover:visible absolute -top-1 right-5">
                                                                                                    <button
                                                                                                        className="items-center border border-transparent rounded-md text-sm font-medium text-grey-500 hover:text-grey-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grey-500"
                                                                                                        onClick={() => this.editKeyword(keyword)}
                                                                                                    >
                                                                                                        <i className="fas fa-edit mr-2"></i>
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="items-center border border-transparent rounded-md text-sm font-medium text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                                                        title="Delete keyword"
                                                                                                        onClick={() => {
                                                                                                            const confirmBox = window.confirm(
                                                                                                                "Do you really want to delete this item?"
                                                                                                            )
                                                                                                            if (confirmBox === true) {
                                                                                                                this.handleKeywordDelete(keyword)
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fas fa-trash"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        }
                                                                        {this.state.showKeywordModal ? (
                                                                            <KeywordModal 
                                                                                formItem={this.state.formItem}
                                                                                toggle={this.toggleKeywordModal}
                                                                                onSave={this.handleKeywordSubmit}
                                                                                assetId={assets[index].id}
                                                                            />
                                                                            ) : 
                                                                            null
                                                                        }
                                                                        <div className="flex items-center justify-end pb-2">
                                                                            <button
                                                                                className="w-full mx-2 mt-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                                                onClick={() => this.createKeyword(asset.id)}
                                                                            >
                                                                                <i className="fas fa-plus mr-2"></i> Add Keyword
                                                                            </button>
                                                                        </div>
                                                                    </Collapse>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                        <button
                                            className="w-full mt-5 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={this.createAsset}
                                        >
                                            <i className="fas fa-plus mr-2"></i> Add Asset
                                        </button>
                                        {this.state.showAssetModal ? (
                                            <AssetModal 
                                                formItem={this.state.formItem}
                                                toggle={this.toggleAssetModal}
                                                onSave={this.handleAssetSubmit}
                                            />
                                            ) : 
                                            null
                                        }

                                        <div className="m-2 mt-6 relative">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-300" />
                                            </div>
                                            <div className="relative flex justify-center">
                                                <span className="px-2 bg-white text-sm text-gray-500">Explore your data</span>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div>
                                                <label htmlFor="range_start_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Filter period
                                                </label>
                                                <div className="mt-1">
                                                    <DatePicker
                                                        selected={ this.state.rangeStartDate }
                                                        onChange={ this.setRangeStart }
                                                        name="range_start_date"
                                                        maxDate={ this.state.rangeEndDate ? new Date(this.state.rangeEndDate) : null }
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholder="dd/mm/yyyy"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label htmlFor="range_end_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    &nbsp;
                                                </label>
                                                <div className="mt-1">
                                                    <DatePicker
                                                        selected={ this.state.rangeEndDate }
                                                        onChange={ this.setRangeEnd }
                                                        name="range_end_date"
                                                        minDate={ this.state.rangeStartDate ? new Date(this.state.rangeStartDate) : null }
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholder="dd/mm/yyyy"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <h2 className="mt-3 text-gray-500 text-sm">Classifications</h2>
                                        <div>
                                            <legend className="mt-1 text-gray-300 text-sm">Strategy</legend>
                                            <div className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                                                    <input 
                                                        id="strategy-and" 
                                                        type="radio" 
                                                        value="and" 
                                                        name="bordered-radio" 
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        checked={this.state.item.and_strategy}
                                                        onChange={this.onStrategyChange}
                                                    />
                                                    <label 
                                                        htmlFor="strategy-and" 
                                                        className="py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                            And
                                                    </label>
                                                </div>
                                                <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                                                    <input 
                                                        id="strategy-or" 
                                                        type="radio" 
                                                        value="or" 
                                                        name="bordered-radio" 
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        checked={this.state.item.and_strategy === false} 
                                                        onChange={this.onStrategyChange}
                                                    />
                                                    <label 
                                                        htmlFor="strategy-or" 
                                                        className="py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                            Or
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="divide-y divide-gray-200">
                                            {classifications.map((classification, index) => {
                                                return (
                                                    <li className="pt-2"
                                                        key={classification.field}
                                                        id={classification.field}
                                                    >
                                                        <label className="block flex justify-between">
                                                            <span>
                                                                {classification.id ? 
                                                                    <a 
                                                                        href="#dashboard" 
                                                                        onClick={e => this.setClassificationActive(e, index, classification)}
                                                                        className={(classification.active ? 'text-green-600 ' : 'text-gray-900 mr-1')}
                                                                    >
                                                                        <i className={'fas ' + (classification.active ? 'fa-toggle-on' : 'fa-toggle-off') + ' mr-1'}></i>
                                                                    </a> :
                                                                    <i className={'fas fa-toggle-off text-gray-100 mr-1'}></i>
                                                                } 
                                                                {classification.name}
                                                            </span>
                                                            <small className="text-blue-500">{(classification.start_value /100)} - {classification.end_value /100}</small>
                                                        </label>
                                                        
                                                        <Slider
                                                            classification={classification}
                                                            index={index}
                                                            investigationId={item.id}
                                                            onSave={this.handleClassificationSubmit}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                        <h2 className="mt-3 text-gray-500 text-sm">Keywords</h2>
                                        {filters.length <= 0 ? 
                                            <p className="p-2 text-sm text-gray-400">No filters yet</p> : 
                                            <ul className="divide-y divide-gray-200">
                                                {filters.map((filter, f_index) => {
                                                    const activeFilter = filter.active;
                                                    return (
                                                        <li 
                                                            key={filter.id} 
                                                            className="relative block hover:bg-gray-50"
                                                        >
                                                            <div className="group flex-1 p-2 pr-3 text-sm truncate" title={filter.name}>
                                                                <a 
                                                                    href="#dashboard" 
                                                                    onClick={e => this.setFilterActive(e, f_index, filter)}
                                                                    className={'font-medium'}
                                                                >
                                                                    <i className={'mr-1 fas ' + (activeFilter ? 'text-green-600 fa-toggle-on' : 'text-gray-900 fa-toggle-off')}></i> {filter.name}
                                                                </a>
                                                                <div className=":visible absolute top-2 right-5">
                                                                    <button
                                                                        className="items-center border border-transparent rounded-md text-sm font-medium text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                        title="Delete filter"
                                                                        onClick={() => {
                                                                            const confirmBox = window.confirm(
                                                                                "Do you really want to delete this item?"
                                                                            )
                                                                            if (confirmBox === true) {
                                                                                this.handleFilterDelete(filter)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        }
                                        {this.state.showFilterModal ? (
                                            <FilterModal 
                                                formItem={this.state.formItem}
                                                toggle={this.toggleFilterModal}
                                                onSave={this.handleFilterSubmit}
                                            />
                                            ) : 
                                            null
                                        }
                                        <div className="flex items-center justify-end pb-2">
                                            <button
                                                className="w-full mx-2 mt-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                onClick={() => this.createFilter(item.id)}
                                            >
                                                <i className="fas fa-plus mr-2"></i> Add Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
                                <div className="flex-1 relative z-0 flex overflow-hidden">
                                    {visualization ? 
                                        <>
                                        <div 
                                            name="dashboard" 
                                            className="w-full bg-white pl-4 sm:pl-6 sm:rounded-lg"
                                        >
                                            <div
                                                className="w-full"
                                            >
                                                <iframe 
                                                    title={visualization.title} 
                                                    src={constClass.KIBANA_BASE + visualization.target + "?embed=true&show-query-input=true&show-top-menu=true&show-time-filter=true&"+
                                                        "_g=("+
                                                            "filters:!(),"+
                                                            "refreshInterval:(pause:!t,value:0),"+
                                                            "time:(from:'" + ((zeroDate(this.state.rangeStartDate)).toISOString()) + "',to:'" + ((zeroDate(this.state.rangeEndDate)).toISOString()) + "')"+
                                                        ")&"+
                                                        "_a=("+
                                                            "description:'',"+
                                                            "filters:!("+
                                                                (filterQuery.length ? filterQuery.join(',') : '') + 
                                                            "),"+
                                                            "fullScreenMode:!f,"+
                                                            "options:(hidePanelTitles:!f,useMargins:!t)," +
                                                            "query:("+ 
                                                                "language:kuery,"+
                                                                "query:'"+
                                                                    "(" + keywordQuery.join('%20or%20') + ")" +
                                                                    (platformQuery.length ? "%20and%20(" + platformQuery.join('%20or%20') + ")" : '') + 
                                                                    (classQuery.length ? "%20and%20(" + classQuery.join('%20' + (this.state.item.and_strategy ? 'and' : 'or') + '%20') + ")" : '') + 
                                                                    // (langQuery.length ? "%20and%20(" + langQuery.join('%20or%20') + ")" : '') + 
                                                                "'"+
                                                            "),"+
                                                            "tags:!(),"+
                                                            "timeRestore:!f,"+
                                                            "title:'" + encodeURIComponent(visualization.title) + "',"+
                                                            "viewMode:view"+
                                                        ")&"
                                                    }
                                                    width="100%"
                                                    height={this.state.columnHeight}
                                                ></iframe>
                                            </div>
                                        </div>
                                        </> : 
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
          </>
        )
    }
}

export default Investigation;

function getHeaders() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = tokenString ? JSON.parse(tokenString).token : null;
  
    return {
        headers: {
          'Authorization': 'token ' + userToken
        }
    }
}
